import React from "react";
import styled from "styled-components";
import BlogNavigation from "../components/BlogNavigation";

import * as StyleVars from "./../styles/cssVariables";

const Title = styled.div`
  display: block;
  line-height: 1.3;
  font-family: "Inter";
  padding: 40px 0 20px;
  font-weight: 300;
  max-width: 800px;
  margin-bottom: 100px;
  color: #303030;
  position: relative;
  background: white;
  z-index: 5;

  margin-left: auto;
  margin-right: auto;

  @media (max-width: 1100px) {
    padding: 8px 16px;
  }

  @media ($tablet-large) {
    padding: 0 16px;
  }

  @media (${StyleVars.mediaMobile}) {
    margin-bottom: 70px;
  }

  img {
    max-width: 100%;
  }

  h2 {
    font-size: 60px;
    font-weight: 700;
    margin: 16px 0 10px 0;
  }

  // sub-title
  span {
    display: block;
    font-size: 30px;
    font-family: "Merriweather";
  }

  @media (${StyleVars.mediaMobile}) {
    padding: 0 40px;
    h2 {
      font-size: 40px;
    }
  }
`;

function stripHtml(html) {
  if (typeof window === "undefined") return html;
  let tmp = document.createElement("DIV");
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || "";
}

class PageTitle extends React.Component {
  render() {
    return (
      <Title>
        <h2>{this.props.title}</h2>
        {this.props.job && (
          <span
            dangerouslySetInnerHTML={{ __html: stripHtml(this.props.job) }}
          />
        )}

        {/* <BlogNavigation pageContext={this.props.pageContext} title={true} /> */}
      </Title>
    );
  }
}

export default PageTitle;
