import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

const Navigation = styled.ul`
  display: flex;
  list-style: none;
  padding: 0;

  &.title {
    margin-top: -82px;
    margin-bottom: 60px;
  }

  li {
    flex: 50%;
    text-align: right;
    padding: 0 20px;
    font-size: 24px;
  }

  li:first-child {
    text-align: left;
  }

  .top {
    text-align: center;
  }

  a {
    text-decoration: underline;
    color: #008cba;
    line-height: inherit;

    &:hover {
      color: #0078a0;
    }
  }

  @media (max-width: 1024px) {
    &.title {
      margin: 20px 0;
    }
  }

  @media (max-width: 700px) {
    li {
      font-size: 18px;
      padding: 0;
    }

    .top {
      flex-direction: row;
    }
  }

  @media (max-width: 480px) {
    flex-direction: column;
    li,
    li:first-child {
      line-height: 40px;
      text-align: center;
    }

    &.title {
      flex-direction: row;
    }
  }
`;

class BlogNavigation extends React.Component {
  render() {
    const title = typeof this.props.title === "undefined" ? false : "title";

    return (
      <div>
        {this.props.pageContext && (
          <Navigation className={title}>
            <li>
              {this.props.pageContext.next && (
                <Link to={this.props.pageContext.next.fields.slug} rel="next">
                  ← {this.props.pageContext.next.frontmatter.title}
                </Link>
              )}
            </li>

            {!title && (
              <li className="top">
                <Link to="/" rel="prev">
                  Browse
                </Link>
              </li>
            )}

            <li>
              {this.props.pageContext.previous && (
                <Link
                  to={this.props.pageContext.previous.fields.slug}
                  rel="prev"
                >
                  {this.props.pageContext.previous.frontmatter.title} →
                </Link>
              )}
            </li>
          </Navigation>
        )}
      </div>
    );
  }
}

export default BlogNavigation;
