import React from "react";

const IconArrowLeft = ({}) => {
  return (
    <>
      <svg
        width="33"
        height="30"
        viewBox="0 0 33 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.585781 13.5858C-0.195267 14.3668 -0.195267 15.6332 0.585781 16.4142L13.3137 29.1421C14.0948 29.9232 15.3611 29.9232 16.1421 29.1421C16.9232 28.3611 16.9232 27.0948 16.1421 26.3137L4.82842 15L16.1421 3.68629C16.9232 2.90524 16.9232 1.63891 16.1421 0.857862C15.3611 0.0768131 14.0948 0.076813 13.3137 0.857861L0.585781 13.5858ZM33 13L2 13L2 17L33 17L33 13Z"
          fill="#666666"
        />
      </svg>
    </>
  );
};

export default IconArrowLeft;
