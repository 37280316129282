import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import UserGridItem from "./UserGridItem";
import LazyImage from "./LazyImage";
import IconArrowLeft from "./Icons/IconArrowLeft";
import IconArrowRight from "./Icons/IconArrowRight";
import QuestionMark from "./../images/people-site-question-mark.jpg";

import * as StyleVars from "./../styles/cssVariables";
import { Title40 } from "./Typography";

const TopWhiteCSS = `
  position: relative;
  margin-top: 150px;

  &:before {
    content: "";
    display: block;
    width: 100%;
    position: absolute;
    top: -50px;
    height: 50px;
    background: white;
    z-index: 5;
  }

  @media(${StyleVars.mediaMobile}) {
    padding-top: 60px;
    padding-bottom: 120px;
    
    &:before {
      display: none;
    }
  }
`;

const UserNavContainer = styled.div`
  background-color: #f1f1f1;
  padding: ${(props) => (props.upsideDown ? "40px 0 250px" : "60px 0 40px")};
  display: flex;
  align-items: center;

  @media (${StyleVars.mediaMobile}) {
    padding-bottom: 60px;
  }

  ${(props) => (props.upsideDown ? TopWhiteCSS : "")}
`;

const NavContainer = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  max-width: 1300px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;

  @media (${StyleVars.mediaMobileLarge}) {
    width: 80%;
    justify-content: space-between;
  }

  @media (${StyleVars.mediaMobile}) {
    width: 90%;
    justify-content: space-between;
  }
`;

const Dps = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  max-width: 1110px;
  margin: 0 20px;
  justify-content: center;
`;

const ActiveUserDP = (props) => `
  position: relative;
  transform: scale(1.1);

  &:before {
    content: "";
    display: block;
    position: absolute;
    background: white;
    z-index: 0;
    width: calc(100% + 40px);
    height: 270px;
    left: -20px;
    top: ${props.upsideDown ? "-50px" : "-20px"};
    box-shadow: 0px 1px 25px rgba(0, 0, 0, 0.3);

    @media(${StyleVars.mediaMobile}) {
      display: ${props.desktopOnly ? "none" : "block"};
      background: #FFFFFF;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 0px 6px #07B0C5;
      width: calc(100% + 20px);
      height: calc(100% + 20px);
      left: -10px;
      top: -10px;
    }
  }
`;

const ArrowLink = styled(Link)`
  opacity: 0;
  visibility: 0;

  @media (${StyleVars.mediaMobileLarge}) {
    opacity: ${(props) => (props.isShown ? "1" : "0")};
    visibility: ${(props) => (props.isShown ? "1" : "0")};
  }
`;

const UserDP = styled.div`
  width: 200px;
  height: 200px;
  margin: 0 20px;
  ${(props) => (props.active ? ActiveUserDP(props) : "")}
  position: relative;

  .gatsby-image-wrapper-constrained {
    height: 100%;
  }

  @media (max-width: 1170px) {
    &.-first {
      display: ${(props) => (props.hasFourth ? "block" : "none")};
    }

    &.-${(props) => (props.hasFirst ? "third" : "fourth")} {
      display: none;
    }
  }

  @media (max-width: 1024px) {
    &.-first {
      display: ${(props) => (props.hasNext ? "none" : "block")};
    }

    &.-previous {
      display: ${(props) => (!props.hasNext ? "none" : "block")};
    }

    &.-${(props) => (props.hasFirst ? "next" : "third")} {
      display: none;
    }
  }

  @media (max-width: 780px) {
    &.-surprise {
      display: none;
    }
  }

  @media (${StyleVars.mediaMobileLarge}) {
    &.-next {
      display: none;
    }
  }

  @media (${StyleVars.mediaMobile}) {
    display: ${(props) => (props.desktopOnly ? "none" : "block")};
  }
`;

const SurpriseImg = styled.img`
  width: 200px;
  height: 200px;
`;

class UserNav extends React.Component {
  render() {
    const {
      profile,
      title,
      slug,
      nearPosts,
      upsideDown,
      firstName,
      profileCropPos,
      featureCropPos
    } = this.props;

    const first = nearPosts[0];
    const previous = nearPosts[1];
    const next = nearPosts[2];
    const surprise = nearPosts[3];
    const third = nearPosts[4];
    const fourth = nearPosts[5];

    return (
      <UserNavContainer upsideDown={upsideDown}>
        <NavContainer>
          <ArrowLink to={previous?.node?.fields?.slug} isShown={previous}>
            <IconArrowLeft />
          </ArrowLink>
          <Dps>
            {first && (
              <UserDP
                hasNext={next}
                hasFourth={fourth}
                className="-first"
                desktopOnly={true}
              >
                <UserGridItem
                  constrained
                  slug={first?.node?.fields?.slug}
                  img={
                    first?.node?.frontmatter?.profile?.childImageSharp
                      ?.gatsbyImageData
                  }
                  firstName={first?.node?.frontmatter?.first_name || ""}
                  profileCropPos={first?.node?.frontmatter?.profile_crop_pos}
                  featureCropPos={first?.node?.frontmatter?.feature_crop_pos}
                  small
                />
              </UserDP>
            )}
            {previous && (
              <UserDP className="-previous" desktopOnly={true}>
                <UserGridItem
                  constrained
                  slug={previous?.node?.fields?.slug}
                  img={
                    previous?.node?.frontmatter?.profile?.childImageSharp
                      ?.gatsbyImageData
                  }
                  firstName={previous?.node?.frontmatter?.first_name || ""}
                  profileCropPos={previous?.node?.frontmatter?.profile_crop_pos}
                  featureCropPos={previous?.node?.frontmatter?.feature_crop_pos}
                  small
                />
              </UserDP>
            )}
            <UserDP className="-current" upsideDown={upsideDown} active={true}>
              <UserGridItem
                constrained
                slug={null}
                img={profile}
                firstName={firstName}
                mobilePaddingBottom="10px"
                profileCropPos={profileCropPos}
                featureCropPos={featureCropPos}
                noMobilePadding
                small
              />
            </UserDP>
            {next && (
              <UserDP className="-next" desktopOnly={true}>
                <UserGridItem
                  constrained
                  slug={next?.node?.fields?.slug}
                  img={
                    next?.node?.frontmatter?.profile?.childImageSharp
                      ?.gatsbyImageData
                  }
                  firstName={next?.node?.frontmatter?.first_name || ""}
                  profileCropPos={next?.node?.frontmatter?.profile_crop_pos}
                  featureCropPos={next?.node?.frontmatter?.feature_crop_pos}
                  small
                />
              </UserDP>
            )}
            {third && (
              <UserDP
                hasFirst={first || previous}
                className="-third"
                desktopOnly={true}
              >
                <UserGridItem
                  constrained
                  slug={third?.node?.fields?.slug}
                  img={
                    third?.node?.frontmatter?.profile?.childImageSharp
                      ?.gatsbyImageData
                  }
                  firstName={third?.node?.frontmatter?.first_name || ""}
                  profileCropPos={third?.node?.frontmatter?.profile_crop_pos}
                  featureCropPos={third?.node?.frontmatter?.feature_crop_pos}
                  small
                />
              </UserDP>
            )}
            {fourth && (
              <UserDP
                hasFirst={first || previous}
                className="-fourth"
                desktopOnly={true}
              >
                <UserGridItem
                  constrained
                  slug={fourth?.node?.fields?.slug}
                  img={
                    fourth?.node?.frontmatter?.profile?.childImageSharp
                      ?.gatsbyImageData
                  }
                  firstName={fourth?.node?.frontmatter?.first_name || ""}
                  profileCropPos={fourth?.node?.frontmatter?.profile_crop_pos}
                  featureCropPos={fourth?.node?.frontmatter?.feature_crop_pos}
                  small
                />
              </UserDP>
            )}
            {surprise && (
              <UserDP
                className="-surprise"
                desktopOnly={true}
                as={Link}
                to={surprise.node.fields.slug}
              >
                <UserGridItem
                  constrained
                  slug={surprise?.node?.fields?.slug}
                  staticImg={QuestionMark}
                  staticImgStyle={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover"
                  }}
                  firstName="Surprise me"
                  small
                />
              </UserDP>
            )}
          </Dps>
          <ArrowLink to={next?.node?.fields?.slug} isShown={next}>
            <IconArrowRight />
          </ArrowLink>
        </NavContainer>
      </UserNavContainer>
    );
  }
}

export default UserNav;
