import React from "react";
import { graphql, Link } from "gatsby";
import Helmet from "react-helmet";
import get from "lodash/get";
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "styled-components";
import Layout from "../components/Layout";
import Title from "../components/PageTitle";
import UserNav from "../components/UserNav";
import * as StyleVars from "./../styles/cssVariables";
import Section from "../components/SectionTitle";

const Main = styled.main``;

const Profile = styled(GatsbyImage)`
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  width: 300px;
  height: 515px;
  object-fit: cover;
  margin: 0 0 40px 0;
  border-radius: 4px;
  float: right;
  margin-left: 20px;

  @media (max-width: 800px) {
    margin-left: 0;
    width: 100%;
  }
`;

const ProfileSmall = styled(Profile)`
  height: 300px;

  @media (max-width: 800px) {
    margin-left: 0;
    height: 400px;
  }
`;

const Content = styled.div`
  font-family: "Inter", sans-serif;
  margin-left: auto;
  margin-right: auto;

  --x-height-multiplier: 0.375;
  --baseline-multiplier: 0.17;
  letter-spacing: 0.01rem;
  font-weight: 400;
  font-style: normal;
  font-size: 21px;
  line-height: 1.58;
  letter-spacing: -0.003em;
  text-rendering: optimizeLegibility;

  @media (max-width: 800px) {
    padding: 0 16px;
  }

  @media (max-width: 1024px) {
    padding: 0 16px;
  }

  p,
  ol,
  ul {
    font-weight: 300;
    line-height: 40px;
    font-size: 1.375rem;
    letter-spacing: -0.24px;
  }

  p,
  li,
  .gatsby-highlight {
    padding-bottom: 0.2rem;
    margin-bottom: 0;
    margin-top: 10px;
  }

  figure {
    margin-bottom: 60px !important;
  }

  .gatsby-resp-image-wrapper {
    max-width: none !important;
  }

  & > div {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }

  .gatsby-resp-image-figure {
    overflow: hidden;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;

    @media (${StyleVars.mediaMobile}) {
      margin: 0;
    }
  }

  .gatsby-resp-image-figure img {
    object-fit: contain;
    object-position: center;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 600;
    margin-bottom: 30px;
    padding-bottom: 0;
    max-width: 800px !important;
    margin-left: auto;
    margin-right: auto;
    color: #1d1d1d;

    @media (${StyleVars.mediaMobile}) {
      line-height: 40px;
    }
  }

  p,
  ul,
  ol {
    font-family: "Merriweather";
    margin-bottom: 30px;
    margin-top: 0;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    font-size: 24px;

    @media (${StyleVars.mediaMobile}) {
      font-size: 1.0625rem;
      line-height: 30px;
    }
  }

  a {
    text-decoration: underline;
    color: #008cba;
    line-height: inherit;

    &:hover {
      color: #0078a0;
    }
  }

  img {
    max-width: 100%;
  }

  h3 {
    max-width: 100%;
    font-size: 30px;
  }

  .gatsby-highlight {
    background: #f3f3f3;
    color: #484848;
    padding: 20px 20px;
    border-radius: 4px;
    font-size: 17px;
    overflow-x: scroll;
  }

  @media (${StyleVars.mediaMobile}) {
    padding: 0 25px;
  }
`;

const Wrapper = styled.div`
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 1100px) {
    padding: 0 16px;
  }

  @media (max-width: 1024px) {
    padding: 0 16px;
  }

  img {
    max-width: 100%;
  }
`;

const TitleLink = styled(Link)`
  color: white;
  text-decoration: underline !important;
`;

const ContentWrapper = styled.div`
  position: relative;
  z-index: 1;
  background: white;
`;

var matchText = function (node, regex, callback, excludeElements) {
  excludeElements ||
    (excludeElements = ["script", "style", "iframe", "canvas"]);
  var child = node.firstChild;

  do {
    switch (child.nodeType) {
      case 1:
        if (excludeElements.indexOf(child.tagName.toLowerCase()) > -1) {
          continue;
        }
        matchText(child, regex, callback, excludeElements);
        break;
      case 3:
        child.data.replace(regex, function (all) {
          var args = [].slice.call(arguments),
            offset = args[args.length - 2],
            newTextNode = child.splitText(offset);

          newTextNode.data = newTextNode.data.substr(all.length);
          callback.apply(window, [child].concat(args));
          child = newTextNode;
        });
        break;
    }
  } while ((child = child.nextSibling));

  return node;
};

class BlogPostTemplate extends React.Component {
  constructor(props) {
    super(props);

    let query;
    if (typeof window !== "undefined") {
      const urlParams = new URLSearchParams(window.location.search);
      query = urlParams.get("query");
    }

    const post = props.data.markdownRemark;
    this.__html = post.html;

    if (query && typeof window !== "undefined") {
      const doc = new DOMParser().parseFromString(post.html, "text/html");
      const re = new RegExp(`(${query})`, "gmi");
      const body = doc.childNodes[0].childNodes[1];
      this.replaceInText(
        body,
        re,
        `<span class="scroll-here" style="background-color: rgba(255, 217, 118, 0.6); font-weight: bold;">$1</span>`
      );
      this.__html = new XMLSerializer().serializeToString(body);
    }
  }

  replaceInText = (element, pattern, replacement) => {
    for (let node of element.childNodes) {
      switch (node.nodeType) {
        case Node.ELEMENT_NODE:
          this.replaceInText(node, pattern, replacement);
          break;
        case Node.TEXT_NODE:
          const wholeText = node.textContent;
          if (!wholeText.trim()) break;

          const parentNode = node.parentElement;

          const newSpan = document.createElement("span");
          newSpan.innerHTML =
            node.textContent.replace(pattern, replacement).trim() + " ";
          newSpan.firstChild;

          parentNode.replaceChild(newSpan, node);

          break;
        case Node.DOCUMENT_NODE:
          this.replaceInText(node, pattern, replacement);
      }
    }
  };

  componentDidMount() {
    setTimeout(() => {
      const word = document.querySelector(".scroll-here");
      if (word) return word.scrollIntoView({ behavior: "smooth" });

      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    });
  }

  sectionTitle = () => {
    return (
      <>
        The <TitleLink to="/">people</TitleLink> who use our boards.
      </>
    );
  };

  render() {
    let nearPosts = [
      ...(this.props.data.first?.edges.length
        ? this.props.data.first?.edges
        : [null]),
      ...(this.props.data.previous?.edges.length
        ? this.props.data.previous?.edges
        : [null]),
      ...(this.props.data.next?.edges.length
        ? this.props.data.next?.edges
        : [null]),
      ...(this.props.data.surprise?.edges.length
        ? this.props.data.surprise?.edges
        : [null]),
      ...(this.props.data.third?.edges.length
        ? this.props.data.third?.edges
        : [null]),
      ...(this.props.data.fourth?.edges.length
        ? this.props.data.fourth?.edges
        : [null])
    ];

    const post = this.props.data.markdownRemark;

    const siteTitle = get(this.props, "data.site.siteMetadata.title");
    const pageTitle = `${post.frontmatter.title} | ${siteTitle}`;
    const { childImageSharp, socialImage } = post.frontmatter.profile;
    const pageContext = this.props.pageContext;

    return (
      <Layout>
        <Helmet>
          <title>{pageTitle}</title>
          <meta name="description" content={post.excerpt} />

          <meta name="og:title" content={pageTitle} />
          <meta name="og:description" content={post.excerpt} />
          {socialImage && (
            <meta
              property="og:image"
              content={socialImage.gatsbyImageData.images.fallback.src}
            />
          )}
        </Helmet>
        <Main>
          <Section title={this.sectionTitle} noSearch numPeople={this.props.data?.numPeople?.totalCount} />
          <div className="people-page">
            <UserNav
              profile={
                post.frontmatter.profile?.childImageSharp?.gatsbyImageData
              }
              title={post.frontmatter.title}
              profileCropPos={post.frontmatter.profile_crop_pos}
              featureCropPos={post.frontmatter.feature_crop_pos}
              nearPosts={nearPosts}
              slug={post.fields.slug}
            />

            <ContentWrapper>
              <Title
                title={post.frontmatter.title}
                job={post.frontmatter.job}
                pageContext={pageContext}
              />

              <Wrapper>
                <Content dangerouslySetInnerHTML={{ __html: this.__html }} />
              </Wrapper>
            </ContentWrapper>

            <UserNav
              profile={
                post.frontmatter.profile?.childImageSharp?.gatsbyImageData
              }
              profileCropPos={post.frontmatter.profile_crop_pos}
              featureCropPos={post.frontmatter.feature_crop_pos}
              title={post.frontmatter.title}
              firstName={post.frontmatter.first_name}
              nearPosts={nearPosts}
              slug={post.fields.slug}
              upsideDown
            />
          </div>
        </Main>
      </Layout>
    );
  }
}

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug(
    $slug: String!
    $first: String
    $previous: String
    $next: String
    $surprise: String
    $third: String
    $fourth: String
  ) {
    site {
      siteMetadata {
        title
        author
      }
    }
    first: allMarkdownRemark(filter: { fields: { slug: { eq: $first } } }) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            first_name
            profile_crop_pos
            feature_crop_pos
            profile {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  transformOptions: { fit: COVER, cropFocus: ATTENTION }
                )
              }
            }
          }
        }
      }
    }
    previous: allMarkdownRemark(
      filter: { fields: { slug: { eq: $previous } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            first_name
            profile_crop_pos
            feature_crop_pos
            profile {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  transformOptions: { fit: COVER, cropFocus: ATTENTION }
                )
              }
            }
          }
        }
      }
    }
    next: allMarkdownRemark(filter: { fields: { slug: { eq: $next } } }) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            first_name
            profile_crop_pos
            feature_crop_pos
            profile {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  transformOptions: { fit: COVER, cropFocus: ATTENTION }
                )
              }
            }
          }
        }
      }
    }
    surprise: allMarkdownRemark(
      filter: { fields: { slug: { eq: $surprise } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            first_name
            profile_crop_pos
            feature_crop_pos
            profile {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  transformOptions: { fit: COVER, cropFocus: ATTENTION }
                )
              }
            }
          }
        }
      }
    }
    third: allMarkdownRemark(filter: { fields: { slug: { eq: $third } } }) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            first_name
            profile_crop_pos
            feature_crop_pos
            profile {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  transformOptions: { fit: COVER, cropFocus: ATTENTION }
                )
              }
            }
          }
        }
      }
    }
    fourth: allMarkdownRemark(filter: { fields: { slug: { eq: $fourth } } }) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            first_name
            profile_crop_pos
            feature_crop_pos
            profile {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  transformOptions: { fit: COVER, cropFocus: ATTENTION }
                )
              }
            }
          }
        }
      }
    }
    numPeople: allMarkdownRemark { totalCount }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      rawMarkdownBody
      excerpt
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        job
        first_name
        last_name
        profile_small
        profile_crop_pos
        feature_crop_pos
        profile {
          childImageSharp {
            gatsbyImageData
          }
          socialImage: childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              transformOptions: { fit: COVER, cropFocus: ATTENTION }
            )
          }
        }
        feature {
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              transformOptions: { fit: COVER, cropFocus: ATTENTION }
            )
          }
        }
      }
    }
  }
`;
